<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-22 15:01:25
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-02 15:07:40
 * @Description  : 面板
-->
<template>
  <div class="container">
    <div class="btn-layout">
      <span class="title font-heavy">
        {{ $t("summary") }}
      </span>
      <el-button type="primary" @click="addShipments" class="add-shipment"
        >+ {{ $t("new_shipment") }}</el-button
      >
      <el-button type="primary" @click="addLoggers" class="add-device"
        >+ {{ $t("new_loggers") }}</el-button
      >
    </div>

    <SummaryData :summaryData="summaryData"></SummaryData>

    <div class="select-box">
      <div class="select">
        <el-popover
          placement="bottom"
          title=""
          width="400"
          trigger="click"
          v-model="popoverVisible"
        >
          <div class="week-style">
            <div class="year-box">
              <img
                src="../../assets/ic_turn_left.png"
                alt=""
                srcset=""
                @click="turnLeft"
              />
              <span class="font-heavy" style="font-size: 26px">{{
                this.yearValue
              }}</span>
              <img
                src="../../assets/ic_turn_right.png"
                alt=""
                srcset=""
                @click="turnRight"
              />
            </div>
            <div>
              <div class="week-box">{{ $t("week") }}</div>

              <div class="item-box">
                <span
                  class="item"
                  @click="selectWeek(item)"
                  v-for="(item, index) in weekList"
                  :key="index"
                  :class="{ highlighted: isHighlighted(item) }"
                >
                  {{ item }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="tab-item font-heavy"
            :class="{ 'is-active': isActive === 'week' }"
            @click="clickTab('week')"
            slot="reference"
          >
            <div class="type">{{ $t("week") }}</div>

            <div class="value font-heavy">{{ weekValue }}</div>
            <!-- <el-date-picker
            class="week-picker"
            v-model="week"
            type="week"
            ref="weekPicker"
            format="WW"
            :placeholder="this.weekValue"
            @change="onChangeWeek"
          ></el-date-picker> -->
          </div>
        </el-popover>

        <div class="v_line"></div>
        <div
          class="tab-item font-heavy"
          :class="isActive == 'month' ? 'is-active' : ''"
          @click="clickTab('month')"
        >
          <div class="type">{{ $t("month") }}</div>
          <div class="value font-heavy">{{ this.monthValue }}</div>
          <el-date-picker
            class="month-picker"
            v-model="month"
            type="month"
            ref="monthPicker"
            format="MM"
            :placeholder="this.monthValue"
            @change="onChangeMonth"
          ></el-date-picker>
        </div>
        <div class="v_line"></div>
        <div
          class="tab-item font-heavy"
          :class="isActive == 'year' ? 'is-active' : ''"
          @click="clickTab('year')"
        >
          <div class="type">{{ $t("year") }}</div>
          <div class="value font-heavy">{{ this.yearValue }}</div>
          <el-date-picker
            class="year-picker"
            v-model="year"
            type="year"
            ref="yearPicker"
            :placeholder="this.yearValue"
            @change="onChangeYear"
          ></el-date-picker>
        </div>
      </div>
    </div>

    <el-row :gutter="20" style="height: 560px">
      <el-col :span="12">
        <div class="desc">
          <!-- total_number_of_started_loggers -->
          <div class="pagination-box">
            <span class="font-heavy">{{ $t("latest_updated_loggers") }} </span>
          </div>
          <div class="function">
            <img
              @click="getDashboardSensorList"
              src="../../assets/ic_refresh.png"
              alt=""
            />
            <!-- <img src="../../assets/ic_more.png" alt="" /> -->
          </div>
        </div>
        <div class="table-box">
          <el-table
            :data="list"
            class="table-style"
            border
            :header-cell-style="{
              background: '#f6f6fa',
              textAlign: 'center',
              color: 'black',
            }"
            :cell-style="{ 'text-align': 'center' }"
            :default-sort="{ prop: 'refreshDate', order: 'descending' }"
            @sort-change="handleSortChange"
            :empty-text="$t('no_data')"
            height="443"
            :row-style="getRowStyle"
          >
            <el-table-column
              :label="$t('device_id')"
              prop="deviceId"
              width="130px"
            >
              <template v-slot:default="scope">
                <span
                  class="logger-detail"
                  @click="
                    scope.row.status !== 9 ? goDeviceDetail(scope.row) : null
                  "
                  :style="{
                    cursor: scope.row.status === 9 ? 'not-allowed' : 'pointer',
                    color: scope.row.status === 9 ? 'rgb(192, 196, 204)' : '',
                  }"
                >
                  {{ scope.row.deviceId }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('device_name')"
              prop="name"
              width="90px"
              sortable
            >
              <template v-slot:default="scope">
                {{ scope.row.name || "-" }}
              </template>
            </el-table-column>
            <!-- 告警状态 1：告警 0：未告警 (设备有过告警，就一直记录为告警) -->
            <el-table-column
              :label="$t('alert_status')"
              prop="alarmInd"
              width="110px"
            >
              <template v-slot:default="scope">
                <div v-if="scope.row.alarmInd == 1" class="alert-style">
                  <img :src="withAlertIcon" width="15" /><span>{{
                    scope.row.alarmNum
                  }}</span>
                </div>
                <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
                  <img :src="withoutAlertIcon" width="15" /><span>{{
                    $t("no_alert")
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <!-- temp温度 wetness湿度 power电量 refreshDate最后更新时间 -->
            <el-table-column
              :label="$t('latest_measurements')"
              width="285"
              prop="refreshDate"
              sortable
            >
              <template v-slot:default="scope">
                <div class="measurements-col">
                  <div class="left">
                    <div
                      class="point"
                      :style="
                        scope.row.status == '3' ||
                        scope.row.status == '4' ||
                        scope.row.status == '9'
                          ? 'background: #ed6e36;'
                          : 'background: #02C06F;'
                      "
                    ></div>
                    <span v-if="scope.row.status == '0'">{{
                      $t("not_started")
                    }}</span>
                    <span v-if="scope.row.status == '1'">{{
                      $t("recording")
                    }}</span>
                    <span v-if="scope.row.status == '2'">{{
                      $t("delay_recording")
                    }}</span>
                    <span
                      v-if="scope.row.status == '3'"
                      style="color: #ed6e36"
                      >{{ $t("ended") }}</span
                    >
                    <span
                      v-if="scope.row.status == '4'"
                      style="color: #ed6e36"
                      >{{ $t("stopped") }}</span
                    >
                    <span v-if="scope.row.status == '9'">{{
                      $t("discard")
                    }}</span>
                  </div>
                  <div class="right">
                    <div class="right-up">
                      <span>{{
                        Number(scope.row.temp)
                          | convertTemperature(setSelectedTempType)
                      }}</span>
                      <span v-if="scope.row.temp">{{
                        setSelectedTempType
                      }}</span>
                      <span style="margin-left: 5px">{{
                        scope.row.wetness
                      }}</span>
                      <!-- 0-20% 20-50% 50-90% 90-100% -->
                      <BatteryInfo
                        v-if="scope.row.battery"
                        :value="scope.row.battery.value"
                      ></BatteryInfo>
                    </div>
                    <div class="right-down">
                      <span>{{
                        scope.row.refreshDate
                          | formatDateWithTimeZone(
                            setSelectedTimeZone,
                            setSelectedLanguage
                          )
                      }}</span>
                    </div>

                    <!-- Display a message if battery is null -->
                  </div>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column :label="$t('address')" prop="address" width="150px"> -->
            <!-- </el-table-column> -->
            <!-- 纬度latitude 经度longitude -->
            <el-table-column
              :label="$t('latest_location')"
              prop=""
              min-width="300px"
            >
              <template v-slot:default="scope">
                <div class="location-col">
                  {{ scope.row.address || "-" }}
                  {{
                    scope.row.addressTime
                      | formatDateWithTimeZone(
                        setSelectedTimeZone,
                        setSelectedLanguage
                      )
                  }}
                  <!-- {{ scope.row.longitude }} {{ scope.row.latitude }} -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="page-style"
            background
            @current-change="handleCurrentChange"
            :current-page="Number(this.pageNum)"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="Number(this.pageSize)"
            layout="total,pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="desc2">
          <!-- <div style="margin-top:30px" class="font-heavy">
          </div> -->
          <div class="count-box">
            <div class="count-box-item font-heavy">
              <span style="margin-top: 22px">{{
                $t("total_number_of_started_loggers")
              }}</span>
            </div>
            <div class="count-box-item" style="margin-left: 10px">
              <div style="color: #262626; font-size: 18px">
                {{ chartTotal }}
              </div>
              <div style="color: #262626; font-size: 12px">
                {{ $t("total") }}
              </div>
            </div>
            <div class="v_line"></div>
            <div class="count-box-item" style="margin-left: 10px">
              <div style="color: #02c06f; font-size: 18px">
                {{ chartWithout }}
              </div>
              <div style="color: #262626; font-size: 12px">
                {{ $t("loggers_without_alerts") }}
              </div>
            </div>
            <div class="v_line"></div>

            <div class="count-box-item" style="margin-left: 10px">
              <div style="color: #ea4c4c; font-size: 18px">{{ chartWith }}</div>
              <div style="color: #262626; font-size: 12px">
                {{ $t("loggers_with_alerts") }}
              </div>
            </div>
          </div>
          <div class="function2">
            <img
              @click="getAlarmTotal"
              src="../../assets/ic_refresh.png"
              alt=""
            />
            <!-- <img src="../../assets/ic_more.png" alt="" /> -->
          </div>
        </div>
        <div class="chart">
          <StackedAreaBarChart ref="chartRef"></StackedAreaBarChart>
        </div>
      </el-col>
    </el-row>

    <!-- 运单模块 -->
    <el-row :gutter="20" style="height: 480px">
      <el-col :span="12">
        <div class="desc">
          <!-- total_number_of_started_loggers -->
          <div class="pagination-box">
            <span class="font-heavy"
              >{{ $t("shipments_with_unchecked_alerts") }}
              <!-- - Showing
              <span v-if="this.total > 10">10</span>
              <span v-if="this.total <= 10">{{ this.total }}</span>
              of
              {{ this.total }} entries -->
            </span>
          </div>
          <div class="function">
            <img
              @click="getShipmentAlarmPageList"
              src="@/assets/ic_refresh.png"
              alt=""
            />
            <!-- <img src="../../assets/ic_more.png" alt="" /> -->
          </div>
        </div>
        <!-- 运单列表 -->
        <div class="table-box">
          <el-table
            :data="shipmentList"
            class="table-style"
            border
            :header-cell-style="{
              background: '#f6f6fa',
              textAlign: 'center',
              color: 'black',
            }"
            :cell-style="{ 'text-align': 'center' }"
            :default-sort="{ prop: 'refreshDate', order: 'descending' }"
            @sort-change="handleShipmentSortChange"
            :empty-text="$t('no_data')"
            height="443"
          >
            <el-table-column
              :label="$t('table_key_shipment_name')"
              prop="name"
              width="130px"
              sortable
            >
              <template v-slot:default="scope">
                <span
                  class="logger-detail"
                  @click="goShipmentDetail(scope.row)"
                >
                  {{ scope.row.name }}</span
                >
              </template>
            </el-table-column>

            <!-- 告警状态 1：告警 0：未告警 (设备有过告警，就一直记录为告警) -->
            <el-table-column
              :label="$t('table_key_shipment_status')"
              prop="status"
              width="100px"
            >
              <template v-slot:default="scope">
                <!-- 0:未开始 1：进行中 9：已结束 -->
                <div class="status-style">
                  <div
                    class="point"
                    :style="
                      scope.row.status == '1'
                        ? 'background: #02C06F;'
                        : 'background: #ed6e36;'
                    "
                  ></div>
                  <span v-if="scope.row.status == '1'">{{
                    $t("tab_on_going")
                  }}</span>

                  <span v-if="scope.row.status == '0'" style="color: #ed6e36">{{
                    $t("tab_not_start")
                  }}</span>
                  <span v-if="scope.row.status == '9'" style="color: #ed6e36">{{
                    $t("tab_ended")
                  }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('alert_status')"
              prop="alarmNum"
              width="120"
            >
              <template v-slot:default="scope">
                <div class="alert-style">
                  <img
                    :src="
                      scope.row.alarmInd == 1 ? withAlertIcon : withoutAlertIcon
                    "
                    width="15"
                  />
                  <span v-if="scope.row.alarmNum > 0">{{
                    $t("unchecked")
                  }}</span>
                  <span v-if="scope.row.alarmNum <= 0">{{
                    $t("checked")
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('start_time')"
              width="150"
              prop="startTime"
            >
              <template v-slot:default="scope">
                {{
                  scope.row.startTime
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
                }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('logger')"
              prop="deviceInfoList"
              width="285"
            >
              <template v-slot:default="scope">
                <div
                  v-if="scope.row.deviceInfoList.length > 0"
                  style="text-align: left"
                >
                  <span
                    v-for="device in scope.row.deviceInfoList"
                    :key="device.deviceId"
                    @click="goDeviceDetail(device)"
                    class="device-detail"
                  >
                    {{ formatDeviceName(device) }}<br />
                  </span>
                </div>
                <div v-else style="text-align: left">-</div>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            class="page-style"
            background
            @current-change="handleShipmentCurrentChange"
            :current-page="Number(this.shipmentPageNum)"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="Number(this.shipmentPageSize)"
            layout="total,pager, next, jumper"
            :total="shipmentTotal"
          >
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="desc2">
          <!-- <div style="margin-top:30px" class="font-heavy">
          </div> -->
          <div class="count-box">
            <div class="count-box-item font-heavy">
              <span style="margin-top: 22px">{{
                $t("shipments_overview")
              }}</span>
            </div>
            <div class="count-box-item" style="margin-left: 6px">
              <div style="color: #262626; font-size: 18px">
                {{ chartShipmentTotal }}
              </div>
              <div style="color: #262626; font-size: 12px">
                {{ $t("shipments_total") }}
              </div>
            </div>
            <div class="v_line"></div>
            <div class="count-box-item" style="margin-left: 10px">
              <div style="color: #02c06f; font-size: 18px">
                {{ chartShipmentWithout }}
              </div>
              <div style="color: #262626; font-size: 12px">
                {{ $t("shipments_without_alerts") }}
              </div>
            </div>
            <div class="v_line"></div>

            <div class="count-box-item" style="margin-left: 6px">
              <div style="color: #ea4c4c; font-size: 18px">
                {{ chartShipmentWith }}
              </div>
              <div style="color: #262626; font-size: 12px">
                {{ $t("shipments_with_alerts") }}
              </div>
            </div>
          </div>
          <div class="function2">
            <img
              @click="getDashboardShipmentTotal"
              src="../../assets/ic_refresh.png"
              alt=""
            />
            <!-- <img src="../../assets/ic_more.png" alt="" /> -->
          </div>
        </div>
        <div class="chart">
          <StackedAreaBarChart ref="chartShipmentRef"></StackedAreaBarChart>
        </div>
      </el-col>
    </el-row>

    <el-col :gutter="24" style="margin-top: 65px">
      <el-row :span="24">
        <div class="desc3 font-heavy">
          {{ $t("locations_of_loggers") }}({{ list.length }})
          <div class="function">
            <img
              @click="getDashboardSensorList"
              src="../../assets/ic_refresh.png"
              alt=""
            />
            <!-- <img src="../../assets/ic_more.png" alt="" /> -->
          </div>
        </div>
        <!-- <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          scroll-wheel-zoom
          dragging
          @ready="handler"
        ></baidu-map> -->
        <div id="container" class="map" v-if="isChina"></div>
        <div id="container2" class="map" v-if="!isChina"></div>
      </el-row>
    </el-col>
    <div class="bottom-box">
      <div class="bottom">
        <el-checkbox v-model="withAlert" @change="onChangeWithAlert">
          <img
            src="../../assets/ic_blue_mark.png"
            alt=""
            srcset=""
            class="custom-img"
            v-if="withAlert"
          />
          <img
            src="../../assets/ic_gray_mark.png"
            alt=""
            srcset=""
            class="custom-img"
            v-if="!withAlert"
          />
        </el-checkbox>
        <img src="@/assets/ic_dangerous.png" alt="" srcset="" />
        <span> {{ $t("loggers_with_alerts") }} ({{ withAlertNum }})</span>
      </div>
      <div class="bottom" style="flex: 1">
        <el-checkbox v-model="withoutAlert" @change="onChangeWithoutAlert">
          <img
            src="@/assets/ic_blue_mark.png"
            alt=""
            srcset=""
            class="custom-img"
            v-if="withoutAlert"
          />
          <img
            src="../../assets/ic_gray_mark.png"
            alt=""
            srcset=""
            class="custom-img"
            v-if="!withoutAlert"
          />
        </el-checkbox>
        <img
          src="../../assets/ic_safety.png"
          alt=""
          srcset=""
          class="custom-img"
        />
        {{ $t("loggers_without_alerts") }} ({{ withoutAlertNum }})
      </div>
    </div>
    <AddLoggersDialog
      ref="addLoggerRef"
      @refresh="refreshData"
    ></AddLoggersDialog>
  </div>
</template>
<script>
import {
  dashboardSummaryApi,
  alarmTotalApi,
  sensorPageListApi,
  getShipmentAlarmPageListApi,
  getDashboardShipmentTotalApi,
} from "@/api/api.js";
import AddLoggersDialog from "../logger/modal/AddLoggersDialog.vue";
import StackedAreaBarChart from "../components/StackedAreaBarChart.vue";
import BatteryInfo from "../components/BatteryInfo.vue";
import SummaryData from "./components/SummaryData.vue";

// import AMapLoader from "@amap/amap-jsapi-loader";

// import withoutAlertIcon from "../../assets/ic_no_alert.png";
// import withAlertIcon from "../../assets/ic_alert.png";
import moment from "moment";
import _ from "lodash";

export default {
  name: "Board",
  data() {
    return {
      withoutAlertIcon: require("@/assets/ic_no_alert.png"),
      withAlertIcon: require("@/assets/ic_alert.png"),
      total: 0,
      shipmentTotal: 0,
      option: {
        color: ["#50D69C", "#f5582f"],

        grid: {
          left: 100,
          right: 100,
          top: 50,
          bottom: 50,
        },
        xAxis: { data: [] },
        yAxis: {
          name: "",
        },
        legend: {
          //这里自定义legend 隐藏总计
          data: [
            {
              name: "",
              // 强制设置图形为圆。
              icon: "circle",
              // 设置文本为红色
              textStyle: {
                color: "red",
              },
            },
            {
              name: "",
              // 强制设置图形为圆。
              icon: "circle",
              // 设置文本为红色
              textStyle: {
                color: "red",
              },
            },
          ],
        },
        // legend: ["", ""],
        unit: "%",
        // axisLabel: {
        //   show: true, // 是否显示刻度标签，默认显示
        //   interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；
        // },
        // y2Height: 70,
        // y1Name: "百分比",
        // x1Name: "家",
        lineSeries: [
          {
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [],
            barWidth: "40px",
            // itemStyle: {
            //   //柱子颜色
            //   normal: {
            //     borderColor: "#fff",
            //     borderWidth: 1,
            //   },
            // },
            // barGap: "10%", // 设置同一类目轴上柱子间的距离为柱宽的20%
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            // emphasis: {
            // focus: "series",
            // },
            data: [],

            barWidth: "40px",
            // itemStyle: {
            //   //柱子颜色
            //   normal: {
            //     borderColor: "#fff",
            //     borderWidth: 0,
            //   },
            // },
            barGap: "10%", // 设置同一类目轴上柱子间的距离为柱宽的20%
          },
        ],
      },
      loggerList: [],
      list: [],
      // map: null,//高德地图
      // markers: [],//高德地图
      week: "",
      month: "",
      year: "",

      weekValue: "",
      monthValue: "",
      yearValue: "1970",

      summaryData: {
        alertNum: 0,
        endedNum: 0,
        orgNum: 0,
        sceneNum: 0,
        startNum: 0,
        totalNum: 0,
        uncheckedAlertNum: 0,
        ongoingShipment: 0,
        endedShipment: 0,
      },
      // timezoneArr: [],
      currentInfoWindow: null,
      // currentOrder: null,
      type: 2,
      startDate: "",
      pageNum: 1,
      pageSize: 10,
      shipmentPageNum: 1,
      shipmentPageSize: 10,
      timezone: 0,
      chartTotal: 0,
      chartWithout: 0,
      chartWith: 0,
      chartShipmentTotal: 0,
      chartShipmentWithout: 0,
      chartShipmentWith: 0,
      isChina: true,
      isActive: "year",
      withAlert: true,
      withoutAlert: true,
      withAlertNum: 0,
      withoutAlertNum: 0,
      weekList: 0,
      popoverVisible: false,
      highlightedWeeks: [], // 需要高亮的周数
      shipmentList: [],
      deviceParams: {
        sortList: [
          {
            name: "refreshDate",
            sort: "desc",
          },
        ],
      },
      shipmentParams: {
        sortList: [
          {
            name: "refreshDate",
            sort: "desc",
          },
        ],
      },
      center: { lat: 39.9042, lng: 116.4074 },
    };
  },
  methods: {
    addLoggers() {
      this.$refs.addLoggerRef.showDialog();
    },
    addShipments() {
      localStorage.setItem("selectedMenuItem", 4);
      this.$store.dispatch("updateSelectedMenuItem", 4);
      this.goPages("/createShipment");
    },
    getDashboardSummary() {
      dashboardSummaryApi().then((r) => {
        console.log("=====", r);
        this.summaryData = r.data;
      });
    },
    getShipmentAlarmPageList() {
      let params = {
        ...this.shipmentParams,
        pageSize: this.shipmentPageSize,
        pageNum: this.shipmentPageNum,
        orgIdList: [],
        timezone: this.timezone,
        // startDate: this.startDate,
        type: this.type,
        // endDate: "",
        startDate: moment(this.startDate).unix(),
        endDate: moment(this.endDate)
          .endOf("day")
          .unix(),
      };
      getShipmentAlarmPageListApi(params).then((r) => {
        console.log("getShipmentAlarmPageListApi=====", r);

        this.shipmentList = r.data.records || [];
        this.shipmentTotal = r.data.total;
      });
    },
    // 运单echart初始化
    getDashboardShipmentTotal() {
      let params = {
        timezone: this.timezone,
        // startDate: this.startDate,
        startDate: moment(this.startDate).unix(),
        endDate: moment(this.endDate)
          .endOf("day")
          .unix(),
        type: this.type,
      };
      getDashboardShipmentTotalApi(params).then((r) => {
        console.log("getDashboardShipmentTotalApi=====", r);
        // console.log("=====", r);
        // this.loggerList = r.data.records || [];
        // this.total = r.data.total;
        let green = [];
        let red = [];
        let week = [];
        let data = r.data.groupResult;

        // this.chartTotal = 0;
        // this.chartWithout = 0;
        // this.chartWith = 0;

        data.forEach((element) => {
          green.push(element.alarmNonTotal);
          red.push(element.alarmTotal);
          week.push(element.name);
          // this.chartWithout += element.alarmNonTotal;
          // this.chartWith += element.alarmTotal;
        });
        // this.chartTotal = this.chartWithout + this.chartWith;
        this.chartShipmentTotal = r.data.total;
        this.chartShipmentWithout = r.data.nonAlarmNum;
        this.chartShipmentWith = r.data.alarmNum;
        this.option.lineSeries[0].data = green;
        this.option.lineSeries[1].data = red;

        this.option.lineSeries[0].name = this.$t("no_alert");
        this.option.lineSeries[1].name = this.$t("number_of_alarm_devices");
        this.option.lineSeries[0].barWidth = this.type == 1 ? "15px" : "40px";
        this.option.lineSeries[1].barWidth = this.type == 1 ? "15px" : "40px";
        this.option.xData = week;
        // this.option.yAxis.name = this.$t("total_missions");

        this.$nextTick(() => {
          this.initShipmentChart();
        });
      });
    },
    getDashboardSensorList() {
      let params = {
        ...this.deviceParams,
        type: this.type, //查询类型 0：Week 1:Month 2: Year
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        timezone: this.timezone,
        // startDate: this.startDate,
        startDate: moment(this.startDate).unix(),
        endDate: moment(this.endDate)
          .endOf("day")
          .unix(),
      };
      console.log("params: ", JSON.stringify(params));

      sensorPageListApi(params).then((r) => {
        console.log("=====", r);
        this.list = r.data.records || [];
        this.total = r.data.total;
        this.loggerList = this.list;
        // console.log(" this.loggerList : ", JSON.stringify(this.loggerList));
        // 过滤并提取有效的经纬度坐标
        const validCoordinates = this.loggerList
          .filter(
            (item) =>
              item.latitude &&
              item.longitude && // 确保经纬度存在且不是空字符串
              parseFloat(item.latitude) &&
              parseFloat(item.longitude) // 确保经纬度是有效的数字
          )
          .map((item) => ({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          }));
        this.center = this.getCenterPoint(validCoordinates);
        console.log(" this.center: ", JSON.stringify(this.center));
        this.withAlertNum = this.loggerList.filter(
          (record) => record.alarmInd === 1
        ).length;
        this.withoutAlertNum = this.loggerList.filter(
          (record) => record.alarmInd === 0
        ).length;
        this.loadMap();
        // if (this.isChina) {
        //   if (typeof window.BMapGL !== "undefined") {
        //     this.initBaiduMap();
        //   } else {
        //     setTimeout(() => {
        //       if (typeof window.BMapGL !== "undefined") {
        //         this.initBaiduMap();
        //       } else {
        //         console.error("加载百度地图失败！");
        //       }
        //     }, 2000); // 等待 2 秒后再次检查
        //   }
        // } else {
        //   this.loadGoogleMapsAPI(this.initGoogleMap);
        // }
      });
    },
    getCenterPoint(coordinates) {
      if (coordinates.length === 0) {
        // 根据 isChina 返回不同的默认中心点
        if (this.isChina) {
          return { lat: 39.9042, lng: 116.4074 }; // 北京
        } else {
          return { lat: 50.1109, lng: 8.6821 }; // 法兰克福（欧洲）
        }
      }

      let x = 0,
        y = 0,
        z = 0;

      for (let coord of coordinates) {
        // 将角度转换为弧度
        const latitude = (coord.lat * Math.PI) / 180;
        const longitude = (coord.lng * Math.PI) / 180;

        // 将经纬度转换到三维笛卡尔坐标系
        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      }

      const total = coordinates.length;

      if (total === 0) return null; // 如果没有有效坐标，则返回null

      // 计算平均位置
      x = x / total;
      y = y / total;
      z = z / total;

      // 转换回经纬度
      const lon = Math.atan2(y, x);
      const hyp = Math.sqrt(x * x + y * y);
      const lat = Math.atan2(z, hyp);

      // 将弧度转换回角度
      return {
        lat: (lat * 180) / Math.PI,
        lng: (lon * 180) / Math.PI,
      };
    },

    loadMap() {
      if (this.isChina) {
        // if (typeof window.BMapGL !== "undefined") {
        //   this.initBaiduMap();
        // } else {
        //   setTimeout(() => {
        //     if (typeof window.BMapGL !== "undefined") {
        //       this.initBaiduMap();
        //     } else {
        //       console.error("加载百度地图失败！");
        //     }
        //   }, 2000); // 等待 2 秒后再次检查
        // }
        // 检查高德地图 API 是否加载成功
        if (typeof window.AMap !== "undefined") {
          console.log("高德地图 API 已加载");
          this.initAMap();
        } else {
          console.error("高德地图 API 未加载，请检查引入是否正确");
        }
      } else {
        this.loadGoogleMapsAPI(this.initGoogleMap);
      }
    },
    goDeviceDetail(row) {
      //  this.$refs.addLoggerRef.showDialog();
      // this.goPages("/loggerDetail", { deviceId: row.deviceId });
      this.$store.dispatch("updateSelectedMenuItem", 3);
      localStorage.setItem("selectedMenuItem", 3);

      let obj = {
        deviceId: row.deviceId,
      };
      this.goPages("/loggerDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },

    formatDeviceName(device) {
      return `${device.name} - #${device.deviceId}`;
    },

    refreshData() {
      this.getDashboardSummary();
      this.getDashboardSensorList();
      this.getAlarmTotal();
      this.getShipmentAlarmPageList();
      this.getDashboardShipmentTotal();
    },
    // goScene() {
    //   this.goPages("/home/scene");
    // },

    // customSortMethod(){
    //    // 假设 refreshDate 是 Unix 时间戳（秒）
    //       return a.refreshDate - b.refreshDate;
    // },
    handleSortChange({ column, prop, order }) {
      console.log("handleSortChange--order: ", order);
      console.log("handleSortChange--column: ", column);
      console.log("handleSortChange--prop: ", prop);
      // if (prop === "battery.timestamp") {
      //   this.sortData(order);
      // }
      // 更新排序参数
      this.deviceParams.sortList = [
        {
          name: prop,
          sort: order === "ascending" ? "asc" : "desc",
        },
      ];

      // 重新加载数据
      this.getDashboardSensorList();
    },
    // sortData(order) {
    //   let reverse = order === "descending" ? -1 : 1;
    //   this.loggerList.sort((a, b) => {
    //     // 对于 Unix 时间戳，我们直接使用数字比较
    //     return reverse * (a.timestamp - b.timestamp);
    //   });
    // },

    handleShipmentSortChange({ column, prop, order }) {
      console.log("handleShipmentSortChange--order: ", order);
      console.log("handleShipmentSortChange--column: ", column);
      console.log("handleShipmentSortChange--prop: ", prop);
      // if (prop === "battery.timestamp") {
      //   this.sortShipmentData(order);
      // }
      this.shipmentParams.sortList = [
        {
          name: prop,
          sort: order === "ascending" ? "asc" : "desc",
        },
      ];
      this.getShipmentAlarmPageList();
    },
    // sortShipmentData(order) {
    //   let reverse = order === "descending" ? -1 : 1;
    //   this.shipmentList.sort((a, b) => {
    //     // 对于 Unix 时间戳，我们直接使用数字比较
    //     return reverse * (a.timestamp - b.timestamp);
    //   });
    // },

    initBaiduMap() {
      var map = new window.BMapGL.Map("container"); // 创建地图实例
      var point = new window.BMapGL.Point(this.center.lng, this.center.lat); // 创建点坐标
      map.centerAndZoom(point, 3); // 初始化地图，设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      // 如果你想单独使用放大缩小按钮，可以使用 ZoomControl 控件
      var zoomControl = new window.BMapGL.ZoomControl({
        anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT, // 设置控件停靠位置
      });
      map.addControl(zoomControl);

      // 遍历坐标点数组，为每个坐标点创建Marker并添加到地图上
      for (let i = 0; i < this.loggerList.length; i++) {
        var cityPoint = new window.BMapGL.Point(
          this.loggerList[i].longitude,
          this.loggerList[i].latitude
        );
        let that = this;
        // 根据 alarmInd 的值选择不同的图标
        var iconUrl =
          that.loggerList[i].alarmInd === 1
            ? that.withAlertIcon
            : that.withoutAlertIcon;
        // 创建一个Icon实例，并指定图标URL
        var myIcon = new window.BMapGL.Icon(
          iconUrl,
          new window.BMapGL.Size(32, 32),
          {
            // 可选参数：
            // offset: 图标相对于Marker的位置偏移量，以像素为单位
            // imageOffset: 图标相对于其左上角的偏移量
            // size: 图标的大小，默认为图片实际大小
            // anchor: 图标锚点，即Marker的位置相对于图标的哪个点
            // anchor: new BMap.Size(16, 32) // 表示Marker的位置位于图标中央偏下的位置
          }
        );
        var marker = new window.BMapGL.Marker(cityPoint, { icon: myIcon });
        map.addOverlay(marker);

        // 为Marker添加点击事件
        marker.addEventListener(
          "click",
          (function(index) {
            return function(e) {
              console.log("Marker " + (index + 1) + " clicked");
              console.log(
                " that.loggerList[i]: ",
                JSON.stringify(that.loggerList[i])
              );
              console.log(
                " that.loggerList[i]: ",
                JSON.stringify(that.loggerList[index + 1])
              );

              // 如果有当前打开的信息窗口，先关闭它
              if (that.currentInfoWindow) {
                that.currentInfoWindow.close();
              }

              // 创建并显示信息窗口
              var infoWindowContent =
                `<div style="height:20px;background:#FFFFFF;line-height:20px;text-align:left;">
                    <div>
                       <span style="font-weight: bold">` +
                that.loggerList[i].name +
                `</span><br>` +
                `ID#` +
                that.loggerList[i].deviceId +
                `</div></br>
                    <div>
                        <span style="font-weight: bold"> ` +
                that.$t("latest_measurements") +
                `:` +
                that.$options.filters.formatDateWithTimeZone(
                  that.loggerList[i].refreshDate,
                  that.setSelectedTimeZone,
                  that.setSelectedLanguage
                ) +
                `</span>
                    </div>
                    <div>` +
                that.$options.filters.convertTemperature(
                  Number(that.loggerList[i].temp),
                  that.setSelectedTempType
                ) +
                that.setSelectedTempType +
                ` ` +
                that.loggerList[i].wetness +
                `</div></br>
                    <div>
                        <span style="font-weight: bold">Last location:</span>
                    </div>
                  <div>` +
                that.loggerList[i].address +
                `</div></br>
                  <div>
                        <span style="font-weight: bold">` +
                that.$t("alert") +
                `:</span></br>` +
                that.loggerList[i].alarmNum +
                `</div>
                 </div>`;

              var opts = {
                width: 400,
                height: 250,
                // title: that.loggerList[i].name,
              };
              var infoWindow = new window.BMapGL.InfoWindow(
                infoWindowContent,
                opts
              );
              this.openInfoWindow(infoWindow, e.point); // 打开信息窗口在Marker的位置
            };
          })(i)
        ); // 传递i给立即执行的函数表达式
      }
    },
    initAMap() {
      // 创建地图实例
      var map = new window.AMap.Map("container", {
        zoom: 3, // 初始化地图级别
        center: [this.center.lng, this.center.lat], // 初始化地图中心点
      });

      map.setMapStyle("amap://styles/normal");

      // // 开启鼠标滚轮缩放
      // map.setZoomEnable(true);
      // 添加默认缩放控件（可选）

      // 添加控件可以通过设置选项来完成，例如：
      map.plugin(["AMap.ToolBar"], function() {
        map.addControl(new window.AMap.ToolBar());
      });

      // 遍历坐标点数组，为每个坐标点创建Marker并添加到地图上
      for (let i = 0; i < this.loggerList.length; i++) {
        const latitude = parseFloat(this.loggerList[i].latitude);
        const longitude = parseFloat(this.loggerList[i].longitude);

        let that = this;
        // 根据 alarmInd 的值选择不同的图标
        var iconUrl =
          that.loggerList[i].alarmInd === 1
            ? that.withAlertIcon
            : that.withoutAlertIcon;

        // 检查经纬度是否有效
        if (!isNaN(latitude) && !isNaN(longitude)) {
          var cityPoint = [longitude, latitude];
          var marker = new window.AMap.Marker({
            position: cityPoint,
            icon: iconUrl, // 图标

            map: map,
          });

          // 创建Marker实例
          // var marker = new window.AMap.Marker({
          //   position: cityPoint, // 坐标点
          //   icon: iconUrl, // 图标
          //   offset: new window.AMap.Pixel(-16, -32), // 图标偏移量
          //   map: map, // 添加到地图
          // });

          // 为Marker添加点击事件
          marker.on(
            "click",
            (function(index) {
              return function(e) {
                console.log("Marker " + (index + 1) + " clicked");
                console.log(
                  " that.loggerList[i]: ",
                  JSON.stringify(that.loggerList[i])
                );
                console.log(
                  " that.loggerList[i]: ",
                  JSON.stringify(that.loggerList[index + 1])
                );

                // 如果有当前打开的信息窗口，先关闭它
                if (that.currentInfoWindow) {
                  that.currentInfoWindow.close();
                }

                // 创建并显示信息窗口
                var infoWindowContent =
                  `<div style="height:20px;background:#FFFFFF;line-height:20px;text-align:left;">
              <div>
                 <span style="font-weight: bold">` +
                  that.loggerList[i].name +
                  `</span><br>` +
                  `ID#` +
                  that.loggerList[i].deviceId +
                  `</div></br>
              <div>
                  <span style="font-weight: bold"> ` +
                  that.$t("latest_measurements") +
                  `:` +
                  that.$options.filters.formatDateWithTimeZone(
                    that.loggerList[i].refreshDate,
                    that.setSelectedTimeZone,
                    that.setSelectedLanguage
                  ) +
                  `</span>
              </div>
              <div>` +
                  that.$options.filters.convertTemperature(
                    Number(that.loggerList[i].temp),
                    that.setSelectedTempType
                  ) +
                  that.setSelectedTempType +
                  ` ` +
                  that.loggerList[i].wetness +
                  `</div></br>
              <div>
                  <span style="font-weight: bold">Last location:</span>
              </div>
            <div>` +
                  that.loggerList[i].address +
                  `</div></br>
            <div>
                  <span style="font-weight: bold">` +
                  that.$t("alert") +
                  `:</span></br>` +
                  that.loggerList[i].alarmNum +
                  `</div>
           </div>`;

                var infoWindow = new window.AMap.InfoWindow({
                  content: infoWindowContent,
                  offset: new window.AMap.Pixel(0, -30), // 信息窗口偏移量
                });

                infoWindow.open(map, e.target.getPosition()); // 打开信息窗口在Marker的位置
                that.currentInfoWindow = infoWindow; // 保存当前信息窗口
              };
            })(i)
          ); // 传递i给立即执行的函数表达式
        } else {
          console.warn(
            `Skipping Marker creation for device ${this.loggerList[i].deviceId} due to invalid coordinates.`
          );
        }
      }
    },
    initGoogleMap() {
      // 创建地图实例
      var map = new window.google.maps.Map(
        document.getElementById("container2"),
        {
          center: { lat: this.center.lat, lng: this.center.lng },
          zoom: 3,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          disableDefaultUI: false,
        }
      );

      // 遍历坐标点数组，为每个坐标点创建Marker并添加到地图上
      console.log("this.loggerList: ============", this.loggerList);
      this.loggerList.forEach((element) => {
        element.latitude = Number(element.latitude);
        element.longitude = Number(element.longitude);
      });
      for (let i = 0; i < this.loggerList.length; i++) {
        var cityPoint = {
          lat: this.loggerList[i].latitude,
          lng: this.loggerList[i].longitude,
        };
        let that = this;
        var iconUrl =
          that.loggerList[i].alarmInd === 1
            ? that.withAlertIcon
            : that.withoutAlertIcon;

        // 创建一个Icon实例
        var myIcon = {
          url: iconUrl,
          size: new window.google.maps.Size(54, 54),
          origin: new window.google.maps.Point(0, 0), // 图标原点
          anchor: new window.google.maps.Point(16, 32), // 锚点
        };

        var marker = new window.google.maps.Marker({
          position: cityPoint,
          map: map,
          icon: myIcon,
        });

        // 为Marker添加点击事件
        window.google.maps.event.addListener(
          marker,
          "click",
          (function(marker, i) {
            return function() {
              console.log("Marker " + (i + 1) + " clicked");

              // 如果有当前打开的信息窗口，先关闭它
              if (that.currentInfoWindow) {
                that.currentInfoWindow.close();
              }

              // 创建并显示信息窗口
              var infoWindowContent =
                `
          <div style="height:200px;background:#FFFFFF;">
            <div><span style="font-weight: bold">` +
                that.loggerList[i].name +
                `</span><br>ID#` +
                that.loggerList[i].deviceId +
                `</div>
            <div><br><span style="font-weight: bold">` +
                that.$t("latest_measurements") +
                `</span> ` +
                that.$options.filters.formatDateWithTimeZone(
                  that.loggerList[i].refreshDate,
                  that.setSelectedTimeZone,
                  that.setSelectedLanguage
                ) +
                `:</div>
            <div>` +
                that.$options.filters.convertTemperature(
                  that.loggerList[i].temp,
                  that.setSelectedTempType
                ) +
                that.setSelectedTempType +
                ` ` +
                that.loggerList[i].wetness +
                `</div>
            <div><br><span style="font-weight: bold">Last location:</span></div>
            <div>` +
                that.loggerList[i].address +
                `</div>
            <div><span style="font-weight: bold"><br>` +
                that.$t("alert") +
                `:</span></div>
            <div>` +
                that.loggerList[i].alarmNum +
                `</div>
          </div>`;

              var infoWindow = new window.google.maps.InfoWindow({
                content: infoWindowContent,
                maxWidth: 400,
                height: 250,
              });

              infoWindow.open(map, marker);
              that.currentInfoWindow = infoWindow;
            };
          })(marker, i)
        );
      }
    },
    initAllChart() {
      this.initChart();
      this.initShipmentChart();
    },
    initChart() {
      this.$refs.chartRef.init(this.option);
    },
    initShipmentChart() {
      this.$refs.chartShipmentRef.init(this.option);
    },
    onChangeWeek(val) {
      this.weekValue = moment(val).isoWeek() + "";
      //查询类型 0：Week 1:Month 2: Year
      this.type = 0;
      console.log(
        "this.weekValue: ",
        this.weekValue + "---this.type---" + this.type
      );

      // 获取选中周的第一天
      this.startDate = moment(val)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      // this.startDate = this.yearValue + "-" + this.monthValue + "-01";
      this.refreshData();
    },
    onChangeMonth(val) {
      this.monthValue = moment(val).month() + 1 + "";
      this.type = 1;
      this.monthValue =
        this.monthValue < 10 ? `0${this.monthValue}` : `${this.monthValue}`;

      console.log(
        "this.monthValue: ",
        this.monthValue + "---this.type---" + this.type
      );
      this.startDate = this.yearValue + "-" + this.monthValue + "-01";
      // 获取选中月份的第一天
      // this.startDate = moment(val)
      //   .startOf("month")
      //   .format("YYYY-MM-DD");
      this.refreshData();
    },
    onChangeYear(val) {
      this.yearValue = moment(val).year() + "";

      this.type = 2;
      console.log(
        "this.yearValue: ",
        this.yearValue + "---this.type---" + this.type
      );
      this.startDate = this.yearValue + "-" + this.monthValue + "-01";
      this.weekList = this.getWeeksInYear(this.yearValue);
      console.log("this.weekList: ", this.weekList);

      // 获取选中年份的第一天
      // this.startDate = moment(val)
      //   .startOf("year")
      //   .format("YYYY-MM-DD");
      this.refreshData();
    },

    handleCurrentChange(val) {
      this.pageNum = 1;
      this.pageNum = `${val}`;
      this.getDashboardSensorList();
    },

    handleShipmentCurrentChange(val) {
      this.shipmentPageNum = 1;
      this.shipmentPageNum = `${val}`;
      this.getShipmentAlarmPageList();
    },

    getAlarmTotal() {
      let params = {
        type: this.type, //查询类型 0：Week 1:Month 2: Year
        // startDate: this.startDate,
        // userId: "",//后台处理参数，前端不用传
        timezone: this.timezone,
        startDate: moment(this.startDate).unix(),
        endDate: moment(this.endDate)
          .endOf("day")
          .unix(),
      };
      console.log("getAlarmTotal---this.startDate: ", this.startDate);

      alarmTotalApi(params).then((r) => {
        console.log("=====", r);
        // this.loggerList = r.data.records || [];
        // this.total = r.data.total;
        let green = [];
        let red = [];
        let week = [];
        let data = r.data.groupResult;

        // this.chartTotal = 0;
        // this.chartWithout = 0;
        // this.chartWith = 0;

        data.forEach((element) => {
          green.push(element.alarmNonTotal);
          red.push(element.alarmTotal);
          week.push(element.name);
          // this.chartWithout += element.alarmNonTotal;
          // this.chartWith += element.alarmTotal;
        });
        // this.chartTotal = this.chartWithout + this.chartWith;
        this.chartTotal = r.data.total;
        this.chartWithout = r.data.nonAlarmNum;
        this.chartWith = r.data.alarmNum;
        this.option.lineSeries[0].data = green;
        this.option.lineSeries[1].data = red;

        this.option.lineSeries[0].name = this.$t("loggers_without_alerts");
        this.option.lineSeries[1].name = this.$t("loggers_with_alerts");
        this.option.lineSeries[0].barWidth = this.type == 1 ? "15px" : "40px";
        this.option.lineSeries[1].barWidth = this.type == 1 ? "15px" : "40px";
        this.option.xData = week;
        // this.option.yAxis.name = this.$t("total_missions");

        this.$nextTick(() => {
          this.initChart();
        });
      });
    },
    getCurrentWeekFirstDay() {
      // 获取当前周的第一天
      // const firstDayOfWeek = moment().startOf("isoWeek");
      // 如果你需要将这个日期赋值给某个数据属性
      // this.startDate = firstDayOfWeek.format("YYYY-MM-DD");
      // console.log("当前周第一天this.startDate: ", this.startDate);
    },
    getCurrentYearFirstDay() {
      // 获取当前年份的第一天
      const firstDayOfYear = moment().startOf("year");
      // 如果你需要将这个日期赋值给某个数据属性
      this.startDate = firstDayOfYear.format("YYYY-MM-DD");
      console.log("当前年第一天 this.yearStartDate: ", this.startDate);
    },
    getCurrentMonthFirstDay() {
      // 获取当前月份的第一天
      const firstDayOfMonth = moment().startOf("month");
      // 如果你需要将这个日期赋值给某个数据属性
      this.startDate = firstDayOfMonth.format("YYYY-MM-DD");
      // console.log("当前月第一天 this.monthStartDate: ", this.startDate);
    },
    getCurrentMonth() {
      // 获取当前月份的第一天
      // const firstDayOfMonth = moment().startOf("month");
      // 如果你需要将这个日期赋值给某个数据属性
      // this.startDate = firstDayOfMonth.format("YYYY-MM-DD");
      // console.log("当前月第一天 this.monthStartDate: ", this.startDate);
      // 获取当前月份的第一天
      const firstDayOfMonth = moment().startOf("month");

      // 如果你需要将这个日期赋值给某个数据属性
      this.monthValue = firstDayOfMonth.format("MM");
      console.log("this.monthValue: ", this.monthValue);
    },

    // 异步加载 Google Maps API
    loadGoogleMapsAPI(callback) {
      // 检查是否已经加载过 Google Maps API
      if (window.google && window.google.maps) {
        console.log("Google Maps API 已经加载");
        callback(); // 直接执行回调
        return;
      }
      var script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAzPvwkS71_zXd53e_yOPBj2eeimWqr8rg&callback=initGoogleMap";
      script.async = true;

      // 设置回调函数
      window.initGoogleMap = function() {
        callback();
      };

      // 添加到文档头部
      document.head.appendChild(script);
    },
    clickTab(type) {
      console.log("type: ", type);
      this.isActive = type;
      switch (this.isActive) {
        case "week":
          // this.$refs.weekPicker.handleFocus();
          // this.getCurrentWeekFirstDay();

          break;
        case "month":
          this.$refs.monthPicker.handleFocus();

          this.getCurrentMonthFirstDay();
          break;
        case "year":
          this.$refs.yearPicker.handleFocus();

          this.getCurrentYearFirstDay();
          break;

        default:
          break;
      }
    },
    handleResize: _.debounce(function() {
      this.getAlarmTotal();
      this.getDashboardShipmentTotal();
    }, 3000),
    onChangeWithAlert(val) {
      console.log("val: ", val);
      this.withAlert = val;

      if (this.withAlert && this.withoutAlert) {
        // 如果两个复选框都被勾选，展示所有记录
        this.loggerList = this.list;
      } else if (this.withAlert) {
        // 只勾选告警
        const filteredRecords = this.list.filter(
          (record) => record.alarmInd === 1
        );
        this.loggerList = filteredRecords;
      } else if (this.withoutAlert) {
        // 如果 only withoutAlert is true, show non-alerts
        const filteredRecords = this.list.filter(
          (record) => record.alarmInd === 0
        );
        this.loggerList = filteredRecords;
      } else {
        // 清空过滤列表
        this.loggerList = [];
      }
      console.log("告警this.loggerList: ", this.loggerList.length);

      this.loadMap();
    },

    onChangeWithoutAlert(val) {
      console.log("val: ", val);
      this.withoutAlert = val;

      if (this.withAlert && this.withoutAlert) {
        // 如果两个复选框都被勾选，展示所有记录
        this.loggerList = this.list;
      } else if (this.withAlert) {
        // 如果 only withAlert is true, show alerts
        const filteredRecords = this.list.filter(
          (record) => record.alarmInd === 1
        );
        this.loggerList = filteredRecords;
      } else if (this.withoutAlert) {
        // 只勾选非告警
        const filteredRecords = this.list.filter(
          (record) => record.alarmInd === 0
        );
        this.loggerList = filteredRecords;
      } else {
        // 清空过滤列表
        this.loggerList = [];
      }
      console.log("非告警this.loggerList: ", this.loggerList.length);

      this.loadMap();
    },
    selectWeek(val) {
      this.popoverVisible = false;
      console.log("val: ", val);
      this.startDate = this.getFirstDayOfWeek(this.yearValue, val);
      console.log(" this.startDate: ", this.startDate);
      this.weekValue = val;
      this.highlightedWeeks = [];
      this.highlightedWeeks.push(Number(this.weekValue));
      //查询类型 0：Week 1:Month 2: Year
      this.type = 0;
      this.getAlarmTotal();
      this.getDashboardShipmentTotal();
      this.getDashboardSensorList();
      this.getShipmentAlarmPageList();
    },

    getFirstDayOfWeek(year, weekNumber) {
      // Moment.js 提供了一个 weekYear 和 week 函数来处理 ISO 周
      // 注意：Moment.js 默认使用 ISO 周，即周一为一周的开始
      const date = moment()
        .isoWeekYear(year)
        .isoWeek(weekNumber)
        .startOf("isoWeek")
        .format("YYYY-MM-DD"); // 使用 isoWeek 保持一致
      return date; // 返回 Date 对象
    },

    isHighlighted(item) {
      return this.highlightedWeeks.includes(item);
    },

    getWeeksInYear(year) {
      // 获取该年的第一个星期一
      const firstMonday = moment(`${year}-01-01`).startOf("isoWeek");
      // // 获取该年的最后一个星期一
      const lastMonday = moment(`${year}-12-31`).endOf("isoWeek");

      // // 计算两个日期之间的周数
      const weeks = lastMonday.diff(firstMonday, "weeks") + 1;

      return weeks;
    },
    turnLeft() {
      // 减少一年
      const year = parseInt(this.yearValue, 10);
      if (!isNaN(year) && year > 1970) {
        this.yearValue = String(year - 1);
      }
    },
    turnRight() {
      // 增加一年
      const year = parseInt(this.yearValue, 10);
      if (!isNaN(year) && year < 9999) {
        this.yearValue = String(year + 1);
      }
    },
    calculateTotalWeeks(year) {
      const isLeapYear = moment([year]).isLeapYear();
      this.weekList = isLeapYear ? 53 : 52;
      console.log(year + "年有" + this.weekList + "周");
    },

    goShipmentDetail(row) {
      console.log("row: ", row);
      this.$store.dispatch("updateSelectedMenuItem", 4);
      localStorage.setItem("selectedMenuItem", 4);

      //  this.$refs.addLoggerRef.showDialog();
      // this.goPages("/loggerDetail", { deviceId: row.deviceId });

      // let obj = {
      // deviceId: row.deviceId,
      // };
      this.goPages("/shipmentDetail", {
        data: encodeURIComponent(JSON.stringify(row)),
      });
    },
    getRowStyle({ row }) {
      console.log("row: ", row.status);
      if (row.status === 9) {
        return {
          "background-color": "#f5f7fa",
          color: "#c0c4cc",
          cursor: "not-allowed",
        };
      }
      return {};
    },
  },
  unmounted() {
    // this.map?.destroy();
  },
  props: {},
  components: {
    AddLoggersDialog,
    StackedAreaBarChart,
    BatteryInfo,
    SummaryData,
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getDashboardSensorList();
      this.getAlarmTotal();
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
      this.getDashboardSensorList();
      this.getAlarmTotal();
    },
    setSelectedTempType(newVal) {
      if (newVal) {
        this.getDashboardSensorList();
        this.getAlarmTotal();
      }
    },
    yearValue: {
      immediate: true,
      handler(newValue) {
        console.log("newValue: ", newValue);
        const year = parseInt(newValue, 10);
        if (!isNaN(year) && year >= 1970 && year <= 9999) {
          this.calculateTotalWeeks(year);
        } else {
          console.error("Invalid year value:", newValue);
        }
      },
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    console.log("setSelectedTimeZone: ", this.setSelectedTimeZone);
    this.timezone = this.setSelectedTimeZone;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    console.log("this.isChina: ", this.isChina);
    this.weekValue = moment().isoWeek() + "";
    console.log("this.weekValue: ", this.weekValue);
    this.highlightedWeeks.push(Number(this.weekValue));
    this.monthValue = `${moment().month() + 1}`;
    console.log("this.monthValue: ", this.monthValue);
    this.yearValue = moment().year() + "";
    console.log("this.yearValue: ", this.yearValue);
    this.weekList = this.getWeeksInYear(this.yearValue);
    console.log("this.weekList: ", this.weekList);

    this.getCurrentYearFirstDay();
    this.getCurrentMonth();
    this.initAllChart();
    this.refreshData();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 清理全局回调函数
    if (window.initGoogleMap) {
      delete window.initGoogleMap;
    }
  },

  created() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="less">
// .el-date-picker__prev-btn {
//   display: none !important;
// }
// .el-date-picker__next-btn {
//   display: none !important;
// }
// .el-date-picker__header {
//   display: none !important;
// }
</style>

<style scoped lang="less">
.container {
  padding: 0 56px 14px 38px;
  display: flex;
  flex-direction: column;

  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }

  .btn-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 72px;
    position: relative;
    .title {
      font-size: 12px;
      position: absolute;
      bottom: 0;
      color: #848484;
      margin-bottom: 4px;
    }
    .add-shipment {
      position: absolute;
      width: 145px;
      height: 41px;
      margin-top: 17px;
      margin-bottom: 14px;
      right: 180px;
    }
    .add-device {
      position: absolute;
      width: 145px;
      height: 41px;
      margin-top: 17px;
      margin-bottom: 14px;
      right: 0;
    }
  }
  .function {
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .bm-view {
    width: 100%;
    height: 300px;
  }
  .logger-detail {
    color: #1a72f4;
    text-decoration: underline;
    cursor: pointer;
  }
  .alert-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    img {
      margin: 0 5px;
    }
  }
  .measurements-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 124px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      .point {
        width: 6px;
        height: 6px;

        border-radius: 3px;
        margin-right: 4px;
      }
    }
    .right {
      flex: 1;
      flex-direction: column;

      .right-up {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        span {
          font-weight: 500;
          color: black;
        }
      }
      .right-down {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
.desc {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
  .pagination-box {
    // border-top: 1px solid #dddfe6;
    // border-bottom: 1px solid #dddfe6;
    // padding: 0 20px;
    // background: white;
    height: 60px;
    line-height: 60px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .pagination {
      margin-top: 30px;
    }
  }
}
.table-box {
  background: white;
  border: 1px solid #bbbbbb;
  border-radius: 8px;
  // width: 100%; /* 或者指定一个具体的像素值，如 800px */
  // max-width: 100%; /* 可以限制最大宽度 */
  // overflow-x: auto; /* 当内容超出时，在X轴上显示滚动条 */
  height: 100%; /* 确保容器高度 */
  overflow: auto; /* 确保容器可以滚动 */
  .table-style {
    width: 100%;
    height: 443px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // overflow-y: auto;
    // overflow-x: auto;
  }
  // ele表格滚动条样式
  // 滚动条的宽度
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    height: 10px; // 纵向滚动条 必写
  }
  // 滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
  }
  // 滚动条整体样式
  ::-webkit-scrollbar {
    width: 6px;
    // height: 1px;
  }
  // 滚动槽
  // ::-webkit-scrollbar-track {
  //   box-shadow:inset 0 0 6px #ffffff;
  //   // box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  //   border-radius: 10px;
  //   background: #f5f5f5;
  // }
  // 滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // background: rgba(60, 108, 254, 1);
    background: #c2c2c2;
    // box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.3)
  }
  .page-style {
    padding-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .location-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .device-detail {
    color: #409eff;
    cursor: pointer;
  }
}
.desc2 {
  // margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  height: 72px;
  line-height: 72px;
  align-items: center;
  justify-content: space-between;
  .count-box {
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    align-items: center;
    height: 25px;
    line-height: 25px;
    margin-bottom: 20px;
    margin-top: 5px;
    .count-box-item {
      display: flex;
      flex-direction: column;
      min-width: 100px;
      margin-top: 20px;
    }
    .v_line {
      height: 20px;
      width: 1px;
      background: #bbbbbb;
      margin-top: 20px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.desc3 {
  margin: 15px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.chart {
  // width: 97%;
  // height: 100px;
  color: white;
  // margin-top: 20px;
  border: 1px solid #bbbbbb;
  height: 100%;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden; /* 隐藏超出部分，防止圆角被超出内容破坏 */
}
.function2 {
  width: 60px;
  // margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.map {
  width: 100%;
  // background: #848484;
  height: 499px;
  // color: white;
}
.bottom-box {
  display: flex;
  flex-direction: row;

  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    img {
      width: 20px;
      height: 20px;
      padding: 0 5px;
    }
  }
}
/deep/.el-table::before {
  height: 0px;
}
.select-box {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 37px;
    margin-top: 17px;
    cursor: pointer;
    justify-content: center;

    .tab-item {
      text-align: center;
      border-radius: 8px;
      padding: 2px 0px;
      width: 58px;
      height: 37px;

      .type {
        font-size: 14px;
        color: #262626;
        font-weight: 500;
      }
      .value {
        font-size: 12px;
        color: #848484;
      }
    }

    .is-active {
      background: #dfedff;
      text-align: center;
      color: #ffffff;
      border-radius: 8px;
      .type {
        font-size: 14px;
        color: #848484;
        font-weight: 500;
      }
      .value {
        font-size: 12px;
        color: #4fd69c;
      }
    }

    .v_line {
      height: 15px;
      width: 1px;
      background: #bbbbbb;
      margin: 0 10px;
    }

    .week-picker {
      visibility: hidden;
    }
    .month-picker {
      visibility: hidden;
    }
    .year-picker {
      visibility: hidden;
    }
  }
}
.week-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .year-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .week-box {
    color: #a2a2a2;
  }
  .item-box {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 重复 6 次，每次占据 1/6 的宽度 */
    text-align: center;
    padding-inline-start: 0px !important;
    border-top: 1px solid #ececec;
    border-left: 1px solid #ececec;
    // border-right: 1px solid #ececec;

    .item {
      color: #bababa;
      text-align: center;
      font-size: 12px;
      border-bottom: 1px solid #ececec;
      border-right: 1px solid #ececec;
      padding: 5px 25px;
    }
    .highlighted {
      color: #409eff; /* 高亮的文字颜色为蓝色 */
    }
  }
}

/deep/.el-checkbox .el-checkbox__inner {
  display: none;
}

/deep/.el-checkbox .custom-img {
  width: 20px; /* 根据需要设置图片大小 */
  height: 20px;
  vertical-align: middle; /* 确保图片与文字垂直居中 */
  margin-right: 5px; /* 图片与文字间的距离 */
}

// /deep/.el-checkbox .el-checkbox__label {
//   display: inline-flex;
//   align-items: center;
// }
.status-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .point {
    width: 6px;
    height: 6px;

    border-radius: 3px;
    margin-right: 4px;
  }
}
</style>
